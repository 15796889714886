import React from 'react';
import Layout from '../components/layout';

const NotFoundPage = () => (
  <Layout>
    <h1 className="text-center">404</h1>
    <div className="text-center">
      <p>
        404
        <br />
        Page not found
      </p>
    </div>
  </Layout>
);

export default NotFoundPage;
